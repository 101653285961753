<template>
    <el-main class="home-cont-main eTrip-section-cont com-eTrip-section">
        <iframe :src="url" frameborder="0" style="height: 100vh;width: 100%"></iframe>
    </el-main>
</template>

<script type="text/ecmascript-6">
    import Cookies from 'js-cookie'

    export default {
        //定义模版数据
        data() {
            return {
                content: null,
                url: `//${window.location.host}/backend_server/guiderController/guiderListByParam.do`
            }
        },
        mounted() {
            //iframe访问改域名会自动清楚localStorage下面内容 把登录信息保存下 防止跳出登录
            this.loginUserInfo = this.localStorage.getObject("loginUserInfo");
            //写死一个登录section进去 可以在浏览器登录老系统，这样的section也是共享的
            Cookies.set("900etripBackendSessionid", "ea84729b-2a95-45fc-ac87-df40129fa9aa_backend_10578739");
            this.timer = setTimeout(() => {
                this.loginUserInfo = this.localStorage.setObject("loginUserInfo", this.loginUserInfo);
            }, 1000)
        },
        beforeDestroy() {
            clearTimeout(this.timer)
        }
    }
</script>